<template>
  <router-view/>
</template>
<script>

export default {
  name: 'DefaultLayout',
  components: {},
}
</script>
