<template>
  <div class="footer-wrapper">
    <div class="row">
      <div class="col-lg footer-content">
        <h1>Kontakt</h1>
        <p>Rodelsbach 6</p>
        <p>4463 Großraming</p>
        <p>
          <a href="tel:+4372547385">07254 / 73 85</a>
        </p>
        <p>
          <a href="mailto:office@polizzenprofi.at">office@polizzenprofi.at</a>
        </p>
      </div>
      <div class="col-lg footer-content">
        <h1>Service</h1>
        <p>Polizzencheck</p>
        <p>Schadenservice</p>
        <p>KFZ Zulassungen</p>
        <p>Kundenberatung aller Versicherungen</p>
      </div>
      <div class="col-lg footer-content">
        <h1>Über uns</h1>
        <p>Kundenfreundlich</p>
        <p>Kunden orientiert</p>
        <p>Kunde ist König</p>
      </div>
      <div class="col-lg footer-content">
        <h1>Einfach anrufen</h1>
        <p>Rufen Sie uns heute noch an und vereinbaren Sie einen Termin!</p>
        <p>
          <span>Telefon: </span><a href="tel:+4372547385">07254 / 73 85</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
.footer-wrapper {
  padding: 2rem;
  background-color: black;
  width: 100%;
}

.footer-content {
  color: lightgrey;
}

.row {
  padding-right: 16%;
  padding-left: 16%;
}
</style>