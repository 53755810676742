import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from "@/layouts/DefaultLayout";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/start',
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/ueber-uns',
    name: 'ueber-uns',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/historie',
    name: 'historie',
    component: () => import('@/views/History.vue')
  },
  {
    path: '/unternehmensphilosophie',
    name: 'unternehmensphilosophie',
    component: () => import('@/views/Philosophy.vue')
  },
  {
    path: '/versicherungen',
    name: 'versicherungen',
    component: () => import('@/views/Insurances.vue')
  },
  {
    path: '/finanzierung',
    name: 'finanzierung',
    component: () => import('@/views/Financing.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('@/views/Portfolio.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/Partners.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import('@/views/Contact.vue')
  },  {
    path: '/aktuelles',
    name: 'aktuelles',
    component: () => import('@/views/NewsfeedFacebook.vue')
  },
  {
    path: '/download-center',
    name: 'download-center',
    component: () => import('@/views/DownloadCenter.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory('/folder/'),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
