<template>
  <div class="wrapper">
    <nav class="navbar sticky-top navbar-expand nav">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <img class="navbar-logo" v-bind:width="[isScroll ? '200' : '300']" src="./assets/logo.jpg" @click="routeToStart">
        <ul class="navbar-nav">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkerDropdown" aria-controls="navbarNavDarkerDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDarkerDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Über uns
              </a>
              <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkerDropdownMenuLink">
                <li><router-link :to="'/ueber-uns'" class="dropdown-item">Über uns</router-link></li>
                <li><router-link :to="'/historie'" class="dropdown-item">Unsere Historie</router-link></li>
                <li><router-link :to="'/unternehmensphilosophie'" class="dropdown-item">Unternehmensphilosophie</router-link></li>
              </ul>
            </li>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Versicherungen
            </a>
            <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
              <li><router-link :to="'/versicherungen'" class="dropdown-item">Versicherungen</router-link></li>
              <li><router-link :to="'/finanzierung'" class="dropdown-item">Finanzierung</router-link></li>
              <li><router-link :to="'/portfolio'" class="dropdown-item">Produktportfolio</router-link></li>
            </ul>
          </li>
          <li class="nav-item">
              <router-link :to="'/partner'" class="nav-link">Geschäftspartner</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/aktuelles'" class="nav-link">Aktuelles</router-link>
          </li>
          <li class="nav-button-orange">
            <router-link :to="'/kontakt'" class="nav-link">Kontakt</router-link>
          </li>
          <li class="nav-button-green">
            <router-link :to="'/download-center'" class="nav-link">Download-Center</router-link>
          </li>
          <li class="nav-icon clickable" @click="goToSite('https://www.facebook.com/polizzenprofi')">
            <img class="nav-image" src="./assets/facebook.png">
          </li>
        </ul>
      </div>
    </nav>
    <div>
      <div class="content">
        <router-view></router-view>
        <AppFooter></AppFooter>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from "./components/Footer"

export default {
  name: 'App',
  components: {
    AppFooter
  },
  data: () => {
    return {
      isDesktop: true,
      isScroll: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
  },
  methods: {
    routeToStart() {
      this.$router.push('/start')
    },
    goToSite(link) {
      window.open(link)
    },
    handleScroll() {
      console.log(document.documentElement.scrollTop)
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.isScroll = true
        console.log(this.isScroll)
      } else {
        this.isScroll = false
      }
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
  font-family: "Open Sans", Arial, sans-serif;
  color: #2c3e50;
}

.nav {
  position: fixed;
  width: 100%;
  background-color: white;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  padding-top: 5rem;
}

.navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.nav-item {
  padding-right: 2rem;
}

.nav-item .nav-link {
  color: black;
  font-family: "Open Sans", Arial, sans-serif;
  padding: 1.1rem 3rem;
}

.nav-item .nav-link:hover {
  color: #0F884E;
}

.nav-item .nav-link:focus {
  color: #0F884E;
}

.nav-icon{
  margin-top: 0.7rem;
  padding-top: 0.1rem;
}

.nav-image{
  width: 24px;
  height: 24px;
}

.navbar-logo{
  margin-left: 18rem;
  max-height: 6.5rem;
  transition: 0.2s;
}

.dropdown-item:hover {
  background-color: lightgrey;
}

.nav-button-orange .nav-link {
  color: inherit;
  font-family: inherit;
  padding: inherit;
}

.nav-button-orange {
  height: 2rem;
  width: 6rem;
  color: white;
  border: 1px solid #ff9e16;
  background: #ff9e16;
  border-radius: 2px;
  font-family: "Open Sans", Arial, sans-serif;
  margin-right: 1rem;
  margin-top: 0.7rem;
  padding-top: 0.1rem;
}

.nav-button-orange:hover {
  background-color: white;
  color: #ff9e16;

}

.nav-button-green .nav-link {
  color: inherit;
  font-family: inherit;
  padding: inherit;
}

.nav-button-green {
  height: 2rem;
  width: 10rem;
  color: white;
  border: 1px solid #0F884E;
  background: #0F884E;
  border-radius: 2px;
  font-family: "Open Sans", Arial, sans-serif;
  margin-right: 1rem;
  margin-top: 0.7rem;
  padding-top: 0.1rem;
}

.nav-button-green:hover {
  background-color: white;
  color: #0F884E;
}

</style>
